import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Select,
  Radio,
  Upload,
  message,
  Modal,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  Checkbox,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  activateCompany,
  deactivateCompany,
  getCities,
  getRegions,
  updateCompanyDetails,
  deleteCompany,
  getBusinessTypes,
  sendUpdatedCompanyToOdoo,
  deleteUpload,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import {
  LockOutlined,
  UnlockOutlined,
  EyeOutlined,
  UploadOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  COMPANY_STATUS_KYC,
  USER_TYPE_ADMIN_STAFF,
} from "../../../configs/constants";
import { AppContext } from "../../../context/AppContextProvider";
import { useNavigate } from "react-router-dom";
import UpdateReseller from "./UpdateReseller";
import UpdateCompanyAdminStaff from "./UpdateCompanyAdminStaff";

const CustomerInfo = ({ company, reloadCallback }) => {
  const { authUser } = useContext(AppContext);
  const [logoUrl, setLogoUrl] = useState("");
  const [logoUploading, setLogoUploading] = useState(false);
  const [crCertificateUrl, setCRCertificateUrl] = useState("");
  const [crCertificateUploading, setCRCertificateUploading] = useState(false);
  const [vatCertificateUrl, setVATCertificateUrl] = useState("");
  const [vatCertificateUploading, setVATCertificateUploading] = useState(false);
  const [crVerificationUrl, setCRVerificationUrl] = useState("");
  const [crVerificationUploading, setCRVerificationUploading] = useState(false);
  const [vatVerificationUrl, setVATVerificationUrl] = useState("");
  const [vatVerificationUploading, setVATVerificationUploading] =
    useState(false);
  const [editModal, setEditModal] = useState(false);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [businessTypeVerification, setBusinessTypeVerification] = useState([]);
  const [vatExempt, setVatExempt] = useState(false);
  const formRef = React.createRef();
  const { t } = useTranslation();
  const [storeConfigurationForm] = Form.useForm();

  let navigate = useNavigate();

  let region_id = company.billing_state?.id;

  const businessTypes = [
    "beauty_and_personal_care",
    "retail_food_drinks",
    "healthcare_and_fitness",
    "home_and_repair",
    "entertainment",
    "professional_services",
    "transportation",
  ];

  useEffect(() => {
    getRegions().then((response) => {
      setRegions(response.data.data.regions);
    });
    getBusinessTypes().then((response) => {
      setBusinessTypeVerification(
        response.data.data.business_type_verifications
      );
    });
  }, []);

  const showEditModel = () => {
    setLogoUrl(company.logo);
    setCRCertificateUrl(company.cr_certificate);
    setVATCertificateUrl(company.vat_certificate);
    setCRVerificationUrl(company.cr_verification);
    setVATVerificationUrl(company.vat_verification);
    // Set Form Fields
    storeConfigurationForm.setFieldsValue({
      id: company.id,
      logo: company.logo,
      business_name: company.name,
      business_type: company.business_type,
      business_type_verification: company.business_type_verification?.id,
      address: company.address,
      cr: company.cr,
      cr_certificate: company.cr_certificate,
      cr_verification: company.cr_verification,
      is_vat_exempt: company.is_vat_exempt,
      vat: company.vat,
      vat_certificate: company.vat_certificate,
      vat_verification: company.vat_verification,
      billing_address: company.billing_address,
      billing_city_id: company.billing_city?.id,
      billing_state_id: company.billing_state?.id,
      billing_country: company.billing_country || "Saudi Arabia",
      billing_post_code: company.billing_post_code,
      round_off: company.round_off,
    });
    setVatExempt(company.is_vat_exempt);
    handleCityRequest(region_id);
    setEditModal(true);
  };

  const uploadCustomRequest = (data, file_name) => {
    const formData = new FormData();
    if (file_name === "logo") {
      formData.append("logo", data.file);
      console.log("logo append");
    } else if (file_name === "cr_certificate") {
      formData.append("cr_certificate", data.file);
    } else if (file_name === "vat_certificate") {
      formData.append("vat_certificate", data.file);
    } else if (file_name === "cr_verification") {
      formData.append("cr_verification", data.file);
    } else if (file_name === "vat_verification") {
      formData.append("vat_verification", data.file);
    }
    updateCompanyDetails(company.id, formData).then(function (response) {
      if (file_name === "logo") {
        setLogoUploading(false);
      } else if (file_name === "cr_certificate") {
        setCRCertificateUploading(false);
      } else if (file_name === "vat_certificate") {
        setVATCertificateUploading(false);
      } else if (file_name === "cr_verification") {
        setCRVerificationUploading(false);
      } else if (file_name === "vat_verification") {
        setVATVerificationUploading(false);
      }

      if (response.data.success === true) {
        if (file_name === "logo") {
          setLogoUrl(response.data.data.user.logo);
        } else if (file_name === "cr_certificate") {
          setCRCertificateUrl(response.data.data.user.cr_certificate);
        } else if (file_name === "vat_certificate") {
          setVATCertificateUrl(response.data.data.user.vat_certificate);
        } else if (file_name === "cr_verification") {
          setCRVerificationUrl(response.data.data.user.cr_verification);
        } else if (file_name === "vat_verification") {
          setVATVerificationUrl(response.data.data.user.vat_verification);
        } else {
          setEditModal(false);
        }
        reloadCallback();
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response.data.message
        );
      }
    });
  };

  const handleChange = (info, file_name) => {
    if (info.file.status === "uploading" && file_name === "logo") {
      setLogoUploading(true);
      setLogoUrl("");
    } else if (
      info.file.status === "uploading" &&
      file_name === "cr_certificate"
    ) {
      setCRCertificateUploading(true);
      setCRCertificateUrl("");
    } else if (
      info.file.status === "uploading" &&
      file_name === "vat_certificate"
    ) {
      setVATCertificateUploading(true);
      setVATCertificateUrl("");
    } else if (
      info.file.status === "uploading" &&
      file_name === "cr_verification"
    ) {
      setCRVerificationUploading(true);
      setCRVerificationUrl("");
    } else if (
      info.file.status === "uploading" &&
      file_name === "vat_verification"
    ) {
      setVATVerificationUploading(true);
      setVATVerificationUrl("");
    }
    return;
  };

  // check the logo before uploading
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t("validate_image_file_type"));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t("validate_image_size_2"));
    }
    return isJpgOrPng && isLt2M;
  }

  // check the cr and vat certificate and verification before uploading
  function beforeUploadCertificate(file) {
    console.log(file.type);
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error(t("validate_image_pdf_file_type"));
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error(t("validate_image_size_8"));
    }
    return isJpgOrPngOrPdf && isLt8M;
  }

  const handleActivate = (company_id) => {
    activateCompany(company_id).then((response) => {
      if (response.data.success === true) {
        NotificationWithIcon(
          "success",
          t("company_activated"),
          t("company_activated_successfully")
        );
        reloadCallback();
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response.data.message
        );
      }
    });
  };

  const handleDeactivate = (company_id) => {
    deactivateCompany(company_id).then((response) => {
      if (response.data.success === true) {
        NotificationWithIcon(
          "success",
          t("company_deactivated"),
          t("company_deactivated_successfully")
        );
        reloadCallback();
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response.data.message
        );
      }
    });
  };

  // send request on finish
  const onFinishConfigForm = (formData) => {
    // if (!company.logo && !logoUrl) {
    //   message.error(t("validate_business_logo_required"));
    // } else
    if (!company.cr_certificate && !crCertificateUrl) {
      message.error(t("validate_cr_certificate_required"));
    } else if (!vatExempt && !company.vat_certificate && !vatCertificateUrl) {
      message.error(t("validate_vat_certificate_required"));
    } else {
      storeConfigurationForm.validateFields().then((formData) => {
        // Set Loading True
        setLoading(true);
        updateCompanyDetails(company.id, formData)
          .then(function (response) {
            if (response.data && response.data.success === true) {
              NotificationWithIcon(
                "success",
                t("company_updated"),
                t("company_updated_successfully")
              );
              reloadCallback();
            } else {
              let error = response.response;
              if (error.status === 422) {
                message.error(error.data.message);
              } else if (error.status === 500) {
                let errorMessages = Object.values(error.data.data.errors);
                errorMessages.forEach(function (key, value) {
                  let temp = Object.values(key);
                  message.error(temp[0]);
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setEditModal(false);
            setLoading(false);
          });
      });
    }
  };

  const uploadButton = (file_name) => (
    <div>
      {file_name === "logo" && logoUploading && <LoadingOutlined />}
      {file_name === "logo" && !logoUploading && <UploadOutlined />}
      {file_name === "cr_certificate" && crCertificateUploading && (
        <LoadingOutlined />
      )}
      {file_name === "cr_certificate" && !crCertificateUploading && (
        <UploadOutlined />
      )}
      {file_name === "vat_certificate" && vatCertificateUploading && (
        <LoadingOutlined />
      )}
      {file_name === "vat_certificate" && !vatCertificateUploading && (
        <UploadOutlined />
      )}
      {file_name === "cr_verification" && crVerificationUploading && (
        <LoadingOutlined />
      )}
      {file_name === "cr_verification" && !crVerificationUploading && (
        <UploadOutlined />
      )}
      {file_name === "vat_verification" && vatVerificationUploading && (
        <LoadingOutlined />
      )}
      {file_name === "vat_verification" && !vatVerificationUploading && (
        <UploadOutlined />
      )}
      <div style={{ marginTop: 8 }}>{t("upload")}</div>
    </div>
  );

  const handleCityRequest = (value) => {
    if (value) {
      getCities(value).then((response) => {
        setCities(response.data.data.cities);
      });
    }
  };

  const handleRegionSelect = (value) => {
    storeConfigurationForm.resetFields(["billing_city_id"]);
    getCities(value).then((response) => {
      setCities(response.data.data.cities);
    });
  };

  const requestReloadCallback = () => {
    reloadCallback();
  };

  const handleDeleteCompany = (id) => {
    deleteCompany(id)
      .then((response) => {
        if (response.data.success === true) {
          // Success
          NotificationWithIcon(
            "success",
            t("company_deleted"),
            t("company_deleted_successfully")
          );
          navigate("/companies");
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  const updateCompanyOnOdoo = (company_id) => {
    sendUpdatedCompanyToOdoo(company_id).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        // Success
        NotificationWithIcon(
          "success",
          t("update_company_on_odoo"),
          t("update_company_on_odoo_message")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
    });
  };

  const handleDeleteUpload = (id, type) => {
    const formData = new FormData();
    formData.append("file_type", type);
    deleteUpload(id, formData)
      .then((response) => {
        if (response.data.success === true) {
          // Reload Callback
          reloadCallback();
          // Success
          NotificationWithIcon(
            "success",
            t("upload_deleted"),
            t("upload_deleted_successfully")
          );
          if (type === "logo") {
            setLogoUrl("");
          } else if (type === "cr_certificate") {
            setCRCertificateUrl("");
          } else if (type === "vat_certificate") {
            setVATCertificateUrl("");
          } else if (type === "cr_verification") {
            setCRVerificationUrl("");
          } else if (type === "vat_verification") {
            setVATVerificationUrl("");
          }
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  return (
    <>
      {/* buttons section */}
      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        {authUser.type !== USER_TYPE_ADMIN_STAFF && (
          <UpdateCompanyAdminStaff
            company={company}
            reloadCallback={requestReloadCallback}
          />
        )}
        {authUser.type !== USER_TYPE_ADMIN_STAFF && (
          <UpdateReseller
            company={company}
            reloadCallback={requestReloadCallback}
          />
        )}
        <Button
          type="primary"
          style={{ marginRight: "20px" }}
          onClick={() => updateCompanyOnOdoo(company.id)}
        >
          {t("update_company_on_odoo")}
        </Button>
        <Button type="primary" style={{ marginRight: "20px" }}>
          <a
            href="https://zatca.gov.sa/en/eServices/Pages/TaxpayerLookup.aspx"
            target="_blank"
            rel="noreferrer"
          >
            {t("visit_website_for_verification")}
          </a>
        </Button>
        <Button
          type="primary"
          onClick={showEditModel}
          style={{ marginRight: "20px" }}
        >
          {t("edit_company_details")}
        </Button>
        {company.cr_verification &&
          company.status !== COMPANY_STATUS_KYC &&
          (company.is_active ? (
            <Button
              type="button"
              onClick={() => handleDeactivate(company.id)}
              style={{
                padding: "2px 10px",
                backgroundColor: "#ff0000",
                color: "#ffffff",
                marginRight: "20px",
              }}
            >
              <LockOutlined title={t("company_deactivate")} />{" "}
              {t("company_deactivate")}
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() => handleActivate(company.id)}
              style={{
                padding: "2px 10px",
                backgroundColor: "#087c08",
                color: "#ffffff",
                marginRight: "20px",
              }}
            >
              <UnlockOutlined title={t("company_activate")} />{" "}
              {t("company_activate")}
            </Button>
          ))}
        {company.status !== 1 && (
          <Popconfirm
            title={t("warning_delete")}
            onConfirm={() => handleDeleteCompany(company.id)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <Button
              type="button"
              style={{
                padding: "2px 10px",
                backgroundColor: "#ff0000",
                color: "#ffffff",
              }}
            >
              <DeleteOutlined title={t("company_deleted")} />
            </Button>
          </Popconfirm>
        )}
      </div>
      {/* show comopany details */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={1} xl={2} xxl={4}></Col>
        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={16}>
          <div
            style={{
              border: "1px solid #6e6e6e",
              padding: "100px 30px 30px",
              marginTop: "90px",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "50%",
                marginLeft: "-86px",
                marginTop: "-86px",
                background: "#ffffff",
                border: "1px solid #6e6e6e",
                padding: "10px",
                borderRadius: "50%",
              }}
            >
              <img
                src={
                  company.logo ? company.logo : "/images/image-not-found.png"
                }
                alt={t("business_logo")}
                width={150}
                style={{
                  borderRadius: "50%",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
            </div>
            <Row gutter={[20, 20]}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("business_name")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.name ? company.name : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("business_type")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.business_type ? company.business_type : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("menu_business_type_verifications")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.business_type_verification
                    ? company.business_type_verification.type
                    : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("cr_number")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.cr ? company.cr : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("cr_certificate")}
                </div>
                <div
                  style={
                    company.cr_certificate
                      ? { border: "1px solid #d9d9d9", padding: "5px" }
                      : { border: "1px solid #d9d9d9", padding: "10px" }
                  }
                >
                  {company.cr_certificate ? (
                    <Button
                      title={t("preview_cr_certificate")}
                      target="_blank"
                      href={company.cr_certificate}
                      type="link"
                    >
                      <EyeOutlined /> {t("preview_cr_certificate")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("cr_verification")}
                </div>
                <div
                  style={
                    company.cr_verification
                      ? { border: "1px solid #d9d9d9", padding: "5px" }
                      : { border: "1px solid #d9d9d9", padding: "10px" }
                  }
                >
                  {company.cr_verification ? (
                    <Button
                      title={t("preview_cr_verification")}
                      target="_blank"
                      href={
                        company.cr_verification ? (
                          company.cr_verification
                        ) : (
                          <div style={{ backgroundColor: "#ff000057" }}>
                            None
                          </div>
                        )
                      }
                      type="link"
                    >
                      <EyeOutlined /> {t("preview_cr_verification")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </div>
              </Col>
              {company.is_vat_exempt ? (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("is_vat_exempt")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {company.is_vat_exempt ? "Yes" : "No"}
                  </div>
                </Col>
              ) : (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("vat_number")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {company.vat ? company.vat : "-"}
                  </div>
                </Col>
              )}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("vat_certificate")}
                </div>
                <div
                  style={
                    company.vat_certificate
                      ? { border: "1px solid #d9d9d9", padding: "5px" }
                      : { border: "1px solid #d9d9d9", padding: "10px" }
                  }
                >
                  {company.vat_certificate ? (
                    <Button
                      title={t("preview_vat_certificate")}
                      target="_blank"
                      href={company.vat_certificate}
                      type="link"
                    >
                      <EyeOutlined /> {t("preview_vat_certificate")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("vat_verification")}
                </div>
                <div
                  style={
                    company.vat_verification
                      ? { border: "1px solid #d9d9d9", padding: "5px" }
                      : { border: "1px solid #d9d9d9", padding: "10px" }
                  }
                >
                  {company.vat_verification ? (
                    <Button
                      title={t("preview_vat_verification")}
                      target="_blank"
                      href={company.vat_verification}
                      type="link"
                    >
                      <EyeOutlined /> {t("preview_vat_verification")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("shop_address")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.address ? company.address : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("billing_address")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.billing_address ? company.billing_address : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("billing_city")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.billing_city ? company.billing_city?.name_en : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("billing_state")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.billing_state ? company.billing_state?.name_en : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("billing_country")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.billing_country ? company.billing_country : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("billing_post_code")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.billing_post_code ? company.billing_post_code : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("allow_round_off")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.round_off ? "Yes" : "No"}
                </div>
              </Col>
              {company.preferred_contact_time && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("preferred_contact_time")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {company.preferred_contact_time
                      ? company.preferred_contact_time
                      : "-"}
                  </div>
                </Col>
              )}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  Master User Contact
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {company.owner_contact ? company.owner_contact : "-"}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={1} xl={2} xxl={4}></Col>
      </Row>
      {/* edit company details */}
      <Modal
        title={t("edit_company_details")}
        open={editModal}
        onCancel={() => {
          setEditModal(false);
        }}
        onOk={onFinishConfigForm}
        width={1000}
        style={{
          top: 20,
        }}
        footer={[
          <Button
            key="back"
            loading={loading}
            onClick={() => setEditModal(false)}
          >
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={onFinishConfigForm}
          >
            {t("update")}
          </Button>,
        ]}
      >
        <Form
          name="store-configuration-form"
          layout="vertical"
          onFinish={onFinishConfigForm}
          style={{ width: "100%" }}
          ref={formRef}
          form={storeConfigurationForm}
        >
          <Form.Item name="id" hidden={true}>
            <input />
          </Form.Item>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="business_name"
                label={t("business_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_business_name_required"),
                    whitespace: true,
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="business_type"
                label={t("business_type")}
                rules={[
                  {
                    required: true,
                    message: t("validate_business_type_required"),
                    whitespace: true,
                  },
                ]}
                hasFeedback
              >
                <Select>
                  {businessTypes.map((business) => (
                    <Select.Option key={business} value={t(business)}>
                      {t(business)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="business_type_verification"
                label={t("menu_business_type_verifications")}
                rules={[
                  {
                    required: true,
                    message: t("validate_business_types_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  allowClear
                  options={businessTypeVerification.map((businessTypeVer) => {
                    return {
                      label: businessTypeVer.type,
                      value: businessTypeVer.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="cr"
                label={t("cr_number")}
                rules={[
                  {
                    required: true,
                    message: t("validate_cr_number_required"),
                  },
                  {
                    min: 10,
                    message: t("validate_cr_number_exact_limit"),
                  },
                  {
                    max: 10,
                    message: t("validate_cr_number_exact_limit"),
                  },
                ]}
                hasFeedback
              >
                <Input maxLength={10} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="is_vat_exempt"
                valuePropName="checked"
                style={{ marginTop: "30px" }}
              >
                <Checkbox
                  onChange={(e) => {
                    setVatExempt(e.target.checked);
                  }}
                >
                  {t("is_vat_exempt")}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              {!vatExempt && (
                <Form.Item
                  name="vat"
                  label={t("vat_number")}
                  rules={[
                    {
                      required: true,
                      message: t("validate_vat_number_required"),
                    },
                    {
                      min: 15,
                      message: t("validate_vat_number_exact_limit"),
                    },
                    {
                      max: 15,
                      message: t("validate_vat_number_exact_limit"),
                    },
                  ]}
                  hasFeedback
                >
                  <Input maxLength={15} />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <div className="ant-col ant-form-item-label">
                <label
                  className="ant-form-item-required"
                  title={t("cr_certificate")}
                >
                  {t("cr_certificate")}
                </label>
              </div>
              <div>
                <Upload
                  name="cr_certificate"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUploadCertificate}
                  onChange={(info) => handleChange(info, "cr_certificate")}
                  customRequest={(data) =>
                    uploadCustomRequest(data, "cr_certificate")
                  }
                >
                  {crCertificateUrl ? (
                    <div>
                      <UploadOutlined />
                      <div style={{ marginTop: 8 }}>{t("change")}</div>
                    </div>
                  ) : (
                    uploadButton("cr_certificate")
                  )}
                </Upload>
                {crCertificateUrl && (
                  <Space>
                    <Button
                      type="primary"
                      href={crCertificateUrl}
                      target="_blank"
                    >
                      <EyeOutlined />
                    </Button>
                    {authUser.type !== USER_TYPE_ADMIN_STAFF && (
                      <Popconfirm
                        title={t("warning_delete")}
                        onConfirm={() =>
                          handleDeleteUpload(company.id, "cr_certificate")
                        }
                        okText={t("yes")}
                        cancelText={t("no")}
                      >
                        <Button danger>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    )}
                  </Space>
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <div className="ant-col ant-form-item-label">
                <label title={t("cr_verification")}>
                  {t("cr_verification")}
                </label>
              </div>
              <div>
                <Upload
                  name="cr_verification"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUploadCertificate}
                  onChange={(info) => handleChange(info, "cr_verification")}
                  customRequest={(data) =>
                    uploadCustomRequest(data, "cr_verification")
                  }
                >
                  {crVerificationUrl ? (
                    <div>
                      <UploadOutlined />
                      <div style={{ marginTop: 8 }}>{t("change")}</div>
                    </div>
                  ) : (
                    uploadButton("cr_verification")
                  )}
                </Upload>

                {crVerificationUrl && (
                  <Space>
                    <Button
                      type="primary"
                      href={crVerificationUrl}
                      target="_blank"
                    >
                      <EyeOutlined />
                    </Button>
                    {authUser.type !== USER_TYPE_ADMIN_STAFF && (
                      <Popconfirm
                        title={t("warning_delete")}
                        onConfirm={() =>
                          handleDeleteUpload(company.id, "cr_verification")
                        }
                        okText={t("yes")}
                        cancelText={t("no")}
                      >
                        <Button danger>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    )}
                  </Space>
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <div className="ant-col ant-form-item-label">
                <label
                  className="ant-form-item-required"
                  title={t("business_logo")}
                >
                  {t("business_logo")}
                </label>
              </div>
              {/* {(authUser.type !== USER_TYPE_ADMIN_STAFF || !logoUrl) && ( */}
              <Upload
                name="logo"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => handleChange(info, "logo")}
                customRequest={(data) => uploadCustomRequest(data, "logo")}
              >
                {logoUrl ? (
                  <img
                    src={logoUrl}
                    alt={t("business_logo")}
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton("logo")
                )}
              </Upload>
              {/* )} */}
              {logoUrl && authUser.type !== USER_TYPE_ADMIN_STAFF && (
                <Popconfirm
                  title={t("warning_delete")}
                  onConfirm={() => handleDeleteUpload(company.id, "logo")}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              )}
            </Col>
            {!vatExempt && (
              <>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <div className="ant-col ant-form-item-label">
                    <label
                      className="ant-form-item-required"
                      title={t("vat_certificate")}
                    >
                      {t("vat_certificate")}
                    </label>
                  </div>
                  <div>
                    <Upload
                      name="vat_certificate"
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={beforeUploadCertificate}
                      onChange={(info) => handleChange(info, "vat_certificate")}
                      customRequest={(data) =>
                        uploadCustomRequest(data, "vat_certificate")
                      }
                    >
                      {vatCertificateUrl ? (
                        <div>
                          <UploadOutlined />
                          <div style={{ marginTop: 8 }}>{t("change")}</div>
                        </div>
                      ) : (
                        uploadButton("vat_certificate")
                      )}
                    </Upload>

                    {vatCertificateUrl && (
                      <Space>
                        <Button
                          type="primary"
                          href={vatCertificateUrl}
                          target="_blank"
                        >
                          <EyeOutlined />
                        </Button>
                        {authUser.type !== USER_TYPE_ADMIN_STAFF && (
                          <Popconfirm
                            title={t("warning_delete")}
                            onConfirm={() =>
                              handleDeleteUpload(company.id, "vat_certificate")
                            }
                            okText={t("yes")}
                            cancelText={t("no")}
                          >
                            <Button danger>
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        )}
                      </Space>
                    )}
                  </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <div className="ant-col ant-form-item-label">
                    <label title={t("vat_verification")}>
                      {t("vat_verification")}
                    </label>
                  </div>
                  <div>
                    <Upload
                      name="vat_verification"
                      listType="picture-card"
                      showUploadList={false}
                      beforeUpload={beforeUploadCertificate}
                      onChange={(info) =>
                        handleChange(info, "vat_verification")
                      }
                      customRequest={(data) =>
                        uploadCustomRequest(data, "vat_verification")
                      }
                    >
                      {vatVerificationUrl ? (
                        <div>
                          <UploadOutlined />
                          <div style={{ marginTop: 8 }}>{t("change")}</div>
                        </div>
                      ) : (
                        uploadButton("vat_verification")
                      )}
                    </Upload>

                    {vatVerificationUrl && (
                      <Space>
                        <Button
                          type="primary"
                          href={vatVerificationUrl}
                          target="_blank"
                        >
                          <EyeOutlined />
                        </Button>
                        {authUser.type !== USER_TYPE_ADMIN_STAFF && (
                          <Popconfirm
                            title={t("warning_delete")}
                            onConfirm={() =>
                              handleDeleteUpload(company.id, "vat_verification")
                            }
                            okText={t("yes")}
                            cancelText={t("no")}
                          >
                            <Button danger>
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        )}
                      </Space>
                    )}
                  </div>
                </Col>
              </>
            )}
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item name="round_off" label={t("allow_round_off")}>
                <Radio.Group buttonStyle="solid" key="allow-round-off">
                  <Radio.Button key="allow-round-off-true" value={true}>
                    {t("yes")}
                  </Radio.Button>
                  <Radio.Button key="allow-round-off-false" value={false}>
                    {t("no")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <h2>Billing Details</h2>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="billing_country"
                label={t("billing_country")}
                rules={[
                  {
                    required: true,
                    message: t("validate_billing_country_required"),
                  },
                ]}
                hasFeedback
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="billing_post_code"
                label={t("billing_post_code")}
                rules={[
                  {
                    required: true,
                    message: t("validate_billing_post_code_required"),
                  },
                ]}
                hasFeedback
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="billing_state_id"
                label={t("billing_state")}
                rules={[
                  {
                    required: true,
                    message: t("validate_billing_state_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  onChange={handleRegionSelect}
                  options={regions.map((region) => {
                    return {
                      label: region.name_en,
                      value: region.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="billing_city_id"
                label={t("billing_city")}
                rules={[
                  {
                    required: true,
                    message: t("validate_billing_city_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  options={cities.map((city) => {
                    return {
                      label: city.name_en,
                      value: city.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="billing_address"
                label={t("billing_address")}
                rules={[
                  {
                    required: true,
                    message: t("validate_billing_address_required"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="address"
                label={t("shop_address")}
                rules={[
                  {
                    required: true,
                    message: t("validate_shop_address_required"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerInfo;
